import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";

import SEO from "../components/seo/Seo";
import Layout from "../components/layout/Layout";
import InlineVideo from "../components/wistia_inline_video_player/WistiaInlineVideoPlayer";
import "../styles/orbit-concept.scss";

const IndexPage = () => {
  useEffect(() => {
    //javascript goes here
    document.querySelector("header.fixed").style.display = "none";
    document.querySelector("footer").style.display = "none";
  });

  return (
    <Layout>
      <SEO title="Orbit Concept" />
      <div className="orbit-concept">
        <Container className="hero-section container flexed">
          <Row>
            <Col className="content-container column">
              <div className="created-by">
                <span>Created by:</span>
                <img
                  className="gh-logo"
                  src={require("../images/svgs/gh-logo.svg")}
                />
              </div>

              <h1>
                Branding
                <br />
                Concept
              </h1>
              <div className="subtext">Orbit Marketing Group</div>
            </Col>
          </Row>
        </Container>
        <Container className="container flexed">
          <Row className="step-row margin-top-minus-15-perc">
            <Col
              className="content-container column step-number-svg"
              xl={2}
              lg={12}
            >
              <img
                src={require("../images/svgs/orbit-concept/01.svg")}
                alt=""
              />
            </Col>
            <Col
              className="content-container column step-text-block"
              xl={4}
              lg={5}
            >
              <h3 className="blue top-bar">THE OVERVIEW</h3>
              <p>
                This go-to-market strategy will be built on the foundation of
                creating a new brand with a strategic digital marketing roadmap.
                We will target B2B clients such as manufacturers, resellers,
                cross-industry channel companies, franchise groups, and
                technology companies. We will use targeted messaging that will
                get the attention of marketing executives seeking independent
                marketing agency support, CMOs, VPs, Directors, Senior Managers,
                Corporate/Channel marketing roles, and Creative Directors.
              </p>
            </Col>
            <Col className="content-container column flex-center" xl={6} lg={6}>
              <img
                src="https://encrypted.tridigitalmarketing.com/wp-content/uploads/2020/05/Group-9122-1.png"
                alt=""
              />
            </Col>
          </Row>
        </Container>
        <Container className="container flexed">
          <Row className="align-items-center step-row">
            <Col className="content-container column" xl={4} lg={6}>
              <img
                src={require("../images/orbit-concept/Group 9708.png")}
                alt=""
              />
            </Col>
            <Col
              className="content-container column step-text-block"
              xl={4}
              lg={5}
            >
              <h3 className="red top-bar">THE VISION</h3>
              <p>
                The visual and content identity of Orbit Marketing Group will be
                developed to match the passion and professional manner of the
                organization. The visual aspect takes inspiration from outer
                space while maintaining a modern, sleek aesthetic. The content
                will speak with a focus on portraying value-based business
                outcomes with knowledge-based messaging. The vision is to
                portray Orbit as a marketing ally and a reliable partner looking
                to grow businesses and relay their message.
              </p>
            </Col>
            <Col
              className="content-container column f-o-0 step-number-svg"
              xl={3}
              lg={12}
            >
              <img
                src={require("../images/svgs/orbit-concept/02.svg")}
                alt=""
              />
            </Col>
          </Row>
        </Container>
        <Container className="container flexed margin-top-minus-100">
          <Row className="align-items-center step-row">
            <Col
              className="content-container column step-number-svg"
              xl={2}
              lg={12}
            >
              <img
                src={require("../images/svgs/orbit-concept/03.svg")}
                alt=""
              />
            </Col>
            <Col
              className="content-container column step-text-block"
              xl={4}
              lg={5}
            >
              <h3 className="dark-blue top-bar">THE GOAL</h3>
              <p>
                With this go-to-market strategy in place, we are aiming to
                accomplish a few goals. For starters, we plan to establish a
                straightforward and united brand identity. Next is to create
                educational and informational content directed towards B2B
                companies. Lastly, we will create a maximum impact go-to-market
                strategy for the specific services Orbit offers and create an
                inbound lead generation strategy through branded content with
                keyword targeting.
              </p>
            </Col>
            <Col className="content-container column flex-center" xl={6} lg={6}>
              <img src={require("../images/orbit-concept/goal.png")} alt="" />
            </Col>
          </Row>
        </Container>
        <Container className="container flexed">
          <Row className="align-items-center step-row">
            <Col className="content-container column" xl={4} lg={6}>
              <img
                src={require("../images/orbit-concept/position statement img.png")}
                alt=""
              />
            </Col>
            <Col
              className="content-container column step-text-block"
              xl={4}
              lg={5}
            >
              <h3 className="red top-bar">POSITION STATEMENT</h3>
              <p>
                To solution providers, resellers, vendors, and manufacturers
                seeking to achieve campaign success, Orbit is the marketing
                agency that helps businesses engage other businesses through
                innovative concepts and solutions to generate results.
                Award-winning Orbit offers resources, expertise, and
                intelligence that only an independent subsidiary of Ingram
                Micro, the world’s largest distribution company, can provide.
              </p>
            </Col>
            <Col
              className="content-container column f-o-0 step-number-svg"
              xl={3}
              lg={12}
            >
              <img
                src={require("../images/svgs/orbit-concept/04-2.svg")}
                alt=""
              />
            </Col>
          </Row>
        </Container>{" "}
        <Container className="logo-concept-container container flexed">
          <Row className="align-items-center step-row">
            <Col className="content-container column top left centered-text">
              <img
                className="centered-text"
                src={require("../images/orbit-concept/logo-concept.png")}
                alt="Logo concept"
              />
            </Col>
          </Row>
        </Container>
        <Container className="logo-container container flexed rainbow-bottom">
          <Row className="align-items-center">
            <Col className="content-container column centered-text">
              <img
                className="centered-text"
                src={require("../images/svgs/orbit-concept/logo.svg")}
                alt="Logo"
              />
            </Col>
          </Row>
        </Container>
        <Container className="logo-breakdown-container container flexed rainbow-top">
          <Row className="align-items-center">
            <p className="big-p first">
              ORBIT’s logo is a clean and bold logo-mark, a reflection of the
              brand’s dedication to a simple and hassle-free delivery of
              services. It is the visual representation of what the Marketing
              Group stands for the name as a reflection of arbitration, things
              coming to a place.
            </p>
          </Row>
          <Row className="flex-center margin-bottom-100">
            <img
              className="centered-text"
              src={require("../images/svgs/orbit-concept/logo-blueprint.svg")}
              alt="logo blueprint"
            />
          </Row>
        </Container>
        <Container className="logo-animation-container container flexed large-video-block">
          <Row className="align-items-center">
            <InlineVideo className="logo-animation" videoID={"b98to54ouo"} />
          </Row>
        </Container>
        <Container className="color-container container flexed two-zero">
          <Row className="align-items-center max-width-1100">
            <h2 className="rainbow-bottom-border">COLOR</h2>
          </Row>
          <Row className="description-row align-items-center">
            <p>
              Each color will represent a major service. The vibrance of each
              color will help to attract attention for both digital and print
              material.
            </p>
          </Row>
          <Row className="flex-center margin-bottom-100">
            <img
              className="centered-text"
              src={require("../images/svgs/orbit-concept/color-concept.svg")}
              alt=""
            />
          </Row>
        </Container>
        <Container className="concept-1-background three-zero">
          <Row className="align-items-center max-width-1100">
            <h2 className="rainbow-bottom-border white-text">Typography</h2>
          </Row>
          <Row className="align-items-center">
            <p className="big-p third white-text">
              Niveau Grotesk is influenced by classical nineteenth-century
              faces, the fonts are based on geometric forms. Because of its
              straight architecture, it punches in big sizes but is very legible
              in smaller sizes and longer texts — in print or on screen.
            </p>
          </Row>
          <Row className="flex-center margin-bottom-100 font-family-desc max-width-1100">
            <Col className="content-container column" xl={4} lg={5}>
              <h2 className="white-h2">Niveau Grotesk</h2>
            </Col>
            <Col className="content-container column" xl={8} lg={7}>
              <h3 className="small-h3">
                The Fonts is equipped for complex, professional typography.{" "}
              </h3>
              <h3 className="small-h3 large-letter-spacing">
                a b c d e f g h I j k l m n o p q r s t u v w x y z @ # $ % *
              </h3>
            </Col>
          </Row>
        </Container>
        <Container className="container flexed four-zero">
          <Row className="align-items-center max-width-1100">
            <h2 className="rainbow-bottom-border">icons</h2>
          </Row>
          <Row className="align-items-center">
            <p className="big-p third">
              Each icon style has a diverse color array while still fitting in
              with the style of the branding. The style is more asymmetrical and
              outline. The icons give more of a creative logical visual.
            </p>
          </Row>
          <Row className="flex-center margin-bottom-100 max-width-1100">
            <Col
              className="content-container column centered-text"
              xl={12}
              lg={12}
            >
              {" "}
              <img
                src={require("../images/orbit-concept/Orbit Icons.png")}
                alt=""
              />
            </Col>{" "}
          </Row>
        </Container>
        <Container className="container flexed ">
          <Row className="align-items-center ">
            <Col className="content-container column centered-text">
              <img
                className="centered-text"
                src={require("../images/orbit-concept/design.png")}
                alt=""
              />
            </Col>
          </Row>
        </Container>
        <Container className="container flexed blue-slash-background"></Container>
        <Container className="container flexed">
          <Row className="align-items-center">
            <Col className="content-container column" xl={6} lg={5}>
              <h2 className="rainbow-bottom-border">
                gravitational BUSINESS PARTNER
              </h2>
              <p className="blue-p-text">
                This concept is a straight forward, professional design and
                content. It’s a literal and pragmatic approach to establishing
                brand identity. With colors, graphics and condensed headers, we
                can establish a clear identity as a marketing company for the
                technology industry.
              </p>
              <p className="blue-p-text second">
                Supported by an intuitive and navigable design, many prospect
                clients will feel comfortable when interacting with ORBIT
                Marketing group material.
              </p>
            </Col>
            <Col className="content-container column" xl={12}>
              <img
                className="img-width-100"
                src={require("../images/orbit-concept/ORBIT-concept.png")}
                alt=""
              />
            </Col>
          </Row>
        </Container>
        <Container className=" concept-display-section z-index-10">
          <Row className="align-items-center">
            <InlineVideo className="logo-animation" videoID={"i262ff5p5u"} />
          </Row>
        </Container>{" "}
        <Container className="orbit-rainbow-background concept-display-section z-index-10">
          <Row className="flex-col ">
            <img
              src={require("../images/orbit-concept/orbit-homepage.png")}
              className="box-shadow margin-top-100"
              alt=""
            />
          </Row>
        </Container>
        <Container className="dark-blue-background container flexed padding-bottom-0 overflow-top">
          <Row className="align-items-center max-width-1100">
            <h2 className="rainbow-bottom-border white-text">
              Collateral with trajectory success. Literally!
            </h2>
          </Row>
          <Row className="align-items-center">
            <p className="big-p fourth white-text">
              The vibrant colors in some items will pop nicely and grab the
              attention of the audience. And making a statement of modernisms
              and talented people as team.
            </p>
          </Row>
          <Row className="align-items-center max-width-1100">
            <p className="quote">
              “Design is not just what it looks like and feels like. Design is
              how it works.”{" "}
              <cite>— Steve Jobs, co-founder of Apple, Inc.</cite>
            </p>
          </Row>
          <Row className="align-items-center centered-text max-width-1100 justify-center">
            <h2 className="centered-text white-text margin-top-100">
              Swag & collaterals
            </h2>
          </Row>
          <Row className="align-items-center centered-text max-width-1100 justify-center">
            <img
              src={require("../images/orbit-concept/orbit-collateral.png")}
              id="orbit-collateral-img"
              className="full-orbit-collateral-img"
              alt=""
            />
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;
